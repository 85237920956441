<template>
  <div class="col main_questions_wrapper_container">
    <!-- Start Server Table For All Questions Large  -->
    <server-table
      class="in_large_screen"
      :count="$store.getters['questionnaire/total']"
      :DataItems="allsStatementsQuestions"
      :filter="filter"
      @Refresh="Refresh"
      @reset="filter = {}"
      :hidden_export_import="true"
      :title="'Team Statements'"
      admin_permission="admin_questions"
      edit_permission="add_questions"
    >
      <template #columns>
        <el-table-column prop="question" label="Questions" min-width="150">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.question }}
            </div>
          </template></el-table-column
        >
        <el-table-column
          prop="Language"
          label="language"
          sortable
          min-width="120"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ language(lang, scope.row.language) }}
            </div>
          </template>
        </el-table-column>
        <!-- // Actions  -->
        <el-table-column prop="" label="_" min-width="100">
          <template slot-scope="scope">
            <div class="td_actions flex justify-content-end">
              <!-- Edit    -->
              <a
                v-tooltip.top-center="`Edit Question`"
                @click="open_statement_dialog(2, scope.row)"
                class="btn btn-warning btn-simple btn-link"
              >
                <i class="fa fa-edit"></i
              ></a>
              <!-- show   -->
              <a
                v-tooltip.top-center="`Show Question`"
                class="btn btn-info btn-link btn-xs"
                @click="show_every_question_details(scope.row)"
              >
                <i class="fa fa-eye"></i>
              </a>
              <!-- Delete  -->
              <a
                @click="remove(scope.row.id)"
                v-tooltip.top-center="`Delete Question`"
                class="btn btn-danger btn-simple btn-link"
              >
                <i class="fa fa-times"></i
              ></a>
            </div>
          </template>
        </el-table-column>
      </template>
      <!--  Questions Filter -->
      <template #filter>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-select
              v-model="filter.language"
              placeholder=" Language"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in lang"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </template>
      <!-- Add Questions  -->
      <template #action>
        <div type="info" class="ml-2" @click="open_statement_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          Add Question
        </div>
      </template>
    </server-table>
    <!-- End Server Table For All Questions Large -->
    <!-- Start Server Table For All Questions Mobile  -->
    <server-table
      class="in_small_screen"
      :count="$store.getters['questionnaire/total']"
      :DataItems="allsStatementsQuestions"
      :filter="filter"
      @Refresh="Refresh"
      @reset="filter = {}"
      :hidden_export_import="true"
      :title="'Team Statements'"
      admin_permission="admin_questions"
      edit_permission="add_questions"
    >
      <template #columns>
        <el-table-column prop="question" label="Questions" min-width="150">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.question }}
            </div>
          </template></el-table-column
        >

        <!-- // Actions  -->
        <el-table-column prop="" label="_" min-width="100">
          <template slot-scope="scope">
            <div class="td_actions flex justify-content-end">
              <!-- Edit    -->
              <a
                v-tooltip.top-center="`Edit Question`"
                @click="open_statement_dialog(2, scope.row)"
                class="btn btn-warning btn-simple btn-link"
              >
                <i class="fa fa-edit"></i
              ></a>
              <!-- show   -->
              <a
                v-tooltip.top-center="`Show Question`"
                class="btn btn-info btn-link btn-xs"
                @click="show_every_question_details(scope.row)"
              >
                <i class="fa fa-eye"></i>
              </a>
              <!-- Delete  -->
              <a
                @click="remove(scope.row.id)"
                v-tooltip.top-center="`Delete Question`"
                class="btn btn-danger btn-simple btn-link"
              >
                <i class="fa fa-times"></i
              ></a>
            </div>
          </template>
        </el-table-column>
      </template>
      <!--  Questions Filter -->
      <template #filter>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-select
              v-model="filter.language"
              placeholder=" Language"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in lang"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </template>
      <!-- Add Questions  -->
      <template #action>
        <div type="info" class="ml-2" @click="open_statement_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          Add Question
        </div>
      </template>
    </server-table>
    <!-- End Server Table For All Questions Mobile -->

    <!-- // Start Add or Questions -->
    <el-dialog
      :title="type == 1 ? `Add Question` : `Edit Question`"
      width="60%"
      :visible.sync="add_statement_dialog"
      top="4vh"
      class="add_attributes_dialog_wrapper"
    >
      <el-form
        :model="statement"
        style="width: 100%"
        ref="addAttributeForm"
        :rules="addAttributeRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24"
            ><el-form-item label="Question" style="width: 100%" prop="question">
              <el-input
                type="text"
                v-model="statement.question"
                placeholder="Please enter title"
                style="width: 100%"
              ></el-input> </el-form-item
          ></el-col>

          <el-col :span="24">
            <el-form-item label="Language" style="width: 100%" prop="language">
              <el-select
                v-model="statement.language"
                placeholder="Please select a language"
                style="width: 100%"
              >
                <el-option
                  v-for="(language, i) in lang"
                  :key="i"
                  :label="language.title"
                  :value="language.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="add_statement_dialog = false"
          >Cancel</el-button
        >
        <el-button
          class="text-center text-capitalize save_button"
          type=""
          @click="save"
          >Save Question</el-button
        >
      </span>
    </el-dialog>
    <!-- // End Add or Questions -->

    <!-- // Start Show  Statement Question  -->
    <el-dialog
      title="Question Details"
      width="60%"
      :visible.sync="show_question_details"
      top="4vh"
      class="add_attributes_dialog_wrapper show_statement_details"
      :close-on-click-modal="false"
    >
      <div v-if="every_question_details">
        <div class="pick_state">
          <h4 class="pick_title">Question</h4>
          <p class="pick_text">{{ every_question_details.question }}</p>
        </div>
        <div class="pick_state">
          <h4 class="pick_title">Language</h4>
          <p class="pick_text">
            {{ language(lang, every_question_details.language) }}
          </p>
        </div>
      </div>
    </el-dialog>
    <!-- // End Show  Statement Question  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      statement: {},
      every_question_details: {},
      show_question_details: false,
      type: null,
      add_statement_dialog: false,
      filter: {},
      addAttributeRules: {
        question: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      }
    };
  },
  components: { ServerTable },
  mounted() {
    this.Refresh();
  },
  computed: {
    allsStatementsQuestions() {
      return this.$store.getters["attributes/getStatementsQuestions"];
    },
    allsStatementsQuestionsCount() {
      return this.$store.getters["attributes/getStatementsQuestionsCount"];
    },
    lang() {
      return this.$store.getters["attributes/getStatementsQuestionsLanguages"];
    }
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("attributes/GetStatementsQuestions", {
        query: query
      });
    },
    // format language for table show
    language(languages, id) {
      let language = languages.filter(el => el.id == id)[0];
      if (language) {
        return language.title;
      }
      return "-";
    },
    open_statement_dialog(type, value) {
      this.statement = { ...value };
      this.add_statement_dialog = true;
      this.type = type;
    },
    save() {
      this.$refs["addAttributeForm"].validate(valid => {
        if (valid) {
          // add new attribute
          if (this.type == 1) {
            this.$store
              .dispatch("attributes/AddStatementQuestion", {
                query: this.statement
              })
              .then(_ => {
                this.Refresh({});
                this.add_statement_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Question has been added.",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          } else {
            this.$store
              .dispatch("attributes/EditStatementQuestion", {
                id: this.statement.id,
                query: this.statement
              })
              .then(_ => {
                this.Refresh({});
                this.add_statement_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Question has been updated.",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          }
        } else {
          return false;
        }
      });
    },
    remove(id) {
      console.log(id);
      Swal.fire({
        title: "",
        text: `Are you sure to delete this Question?`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Delete",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("attributes/DeleteStatementQuestion", id)
            .then(_ => {
              this.Refresh(null);
              Swal.fire({
                title: "",
                text: "Question has been deleted.",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              });
            });
        }
      });
    },
    show_every_question_details(obj) {
      this.show_question_details = true;
      this.every_question_details = obj;
    }
  }
};
</script>

<style lang="scss" scoped>
.in_large_screen {
  display: none;
  @media (min-width: 767px) {
    display: block;
  }
}
.in_small_screen {
  display: none;
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
  }
}
</style>

<style lang="scss">
.main_questions_wrapper_container {
  .add_attributes_dialog_wrapper {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
.show_statement_details {
  .el-dialog__close {
    display: block !important;
    cursor: pointer;
  }
}
</style>
